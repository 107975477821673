<template>
  <div id="page-root">
    <div class="button_nav">
      <div class="button" @click="_back">
        <el-icon class="icon_box">
          <Back />
        </el-icon>
        <div class="back_text">返回</div>
      </div>
    </div>
    <div class="content_nav">
      <div class="article-container">
        <h2 class="title">行业动态 | 2022年浙江省饭店业协会年度风云大会</h2>
        <div class="article-info">
          <span class="yuanchuang">原创</span><span class="green">绿能派</span
          ><span class="timestamp">2022-09-01 16:01</span>
        </div>
        <div class="article-content-wrap">
          <div class="article-content">
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <img
                class="rich_pages wxw-img"
                src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_gif/MBcic5TYfW3YaGibVHtvKuHmXDKm17SbPwsMQRACibMhqxI93vYDRa0qJicolPZGC0OtSq5RYQZCO7SNBcV6mK2ypw/640?wx_fmt=gif"
                style="width: 578px; height: 151px"
              />
            </div>
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <br />
            </div>
            <div
              style="
                text-align: left;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
                color: rgb(62, 62, 62);
              "
            >
              <span style="text-align: justify">浙江省饭店业协会</span
              ><span lang="EN-US" style="text-align: justify">8</span
              ><span style="text-align: justify">月</span
              ><span lang="EN-US" style="text-align: justify">30</span
              ><span style="text-align: justify">日至</span
              ><span lang="EN-US" style="text-align: justify">31</span
              ><span style="text-align: justify">日在杭州黄龙饭店召开“</span
              ><span lang="EN-US" style="text-align: justify">2022</span
              ><span style="text-align: justify"
                >年浙江省饭店风云大会暨浙江省饭店业协会第四届二次理事会扩大会议”，来自全省各级文化和旅游行政部门、专家学者、行业协会、饭店会员单位、合作商代表等共同参加大会。</span
              >
            </div>
            <div
              style="
                margin-bottom: 0em;
                text-align: left;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <strong
                ><span
                  >杭州互为综合能源服务有限公司作为协会合作商单位应邀参加此次会议。</span
                ></strong
              ><span
              ></span
              ><span lang="EN-US">
                <o:p></o:p>
              </span>
            </div>
            <div style="margin-bottom: 0em; margin-left: 8px; margin-right: 8px">
              <span
                ><br
              /></span>
            </div>
            <div style="margin-bottom: 0em; margin-left: 8px; margin-right: 8px">
              <span
                ><img
                  class="rich_pages wxw-img js_insertlocalimg"
                  src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_jpg/MBcic5TYfW3augKXMJRM9Q1fxzyuFNIfPicOxYXficCUt5KbpDqU0g5mc7IxfHNlogY7lZrH6fKN7Ria1pcGYNiaxnw/640?wx_fmt=jpeg"
                  style="
                    margin: 0px;
                    padding: 0px;
                    max-width: 100%;
                    height: auto !important;
                    vertical-align: bottom;
                    color: rgb(51, 51, 51);
                    font-size: 17px;
                  "
              /></span>
            </div>
            <div style="margin-bottom: 0em; margin-left: 8px; margin-right: 8px">
              <span
                ><span
                  style="
                    color: rgb(51, 51, 51);
                    font-size: 14px;
                  "
                  >▲大会现场</span
                ></span
              >
            </div>
            <div style="margin-bottom: 0em; margin-left: 8px; margin-right: 8px">
              <span
                ><span
                  style="
                    color: rgb(51, 51, 51);
                    font-size: 14px;
                  "
                  ><br /></span
              ></span>
            </div>
            <div style="margin-bottom: 0em; margin-left: 8px; margin-right: 8px">
              <span
                ><span
                  style="
                    color: rgb(51, 51, 51);
                    font-size: 14px;
                    font-weight: 400;
                  "
                  ><span
                    style="
                      color: rgb(51, 51, 51);
                      font-size: 17px;
                      font-weight: 400;
                    "
                    >此次会议主旨是为增强饭店企业应对不确定性社情变化的能力，更好地立足新时代，共同商讨疫情常态化下饭店企业的变革与发展，帮助饭店企业找到新的增长引擎。</span
                  ></span
                ></span
              >
            </div>
            <div style="margin-bottom: 0em; margin-left: 8px; margin-right: 8px">
              <span
                ><span
                  style="
                    color: rgb(51, 51, 51);
                    font-size: 14px;
                    font-weight: 400;
                  "
                  ><span
                    style="
                      color: rgb(51, 51, 51);
                      font-size: 17px;
                      font-weight: 400;
                    "
                    ><br /></span></span
              ></span>
            </div>
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <img
                class="rich_pages wxw-img js_insertlocalimg"
                src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_png/MBcic5TYfW3augKXMJRM9Q1fxzyuFNIfPE2rMvibLmXloF3bpefzJpicOTNwUPqbK7jjLjvyLnMcibxHw8Zv3MsPyQ/640?wx_fmt=png"
                style="width: 138px; height: 61px"
              />
            </div>
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <br />
            </div>
            <div style="margin-bottom: 0em; margin-left: 8px; margin-right: 8px">
              <img
                class="rich_pages wxw-img"
                src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_jpg/MBcic5TYfW3augKXMJRM9Q1fxzyuFNIfPksvCrWeWNg60VCptrgFibVhEHH9ZKaJKcibr0Ldc3cqLE0hoklMZAKQA/640?wx_fmt=jpeg"
                style="text-align: center"
              />
            </div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span style="font-size: 14px">▲浙江省文化旅游厅副厅长&nbsp; 许澎</span>
            </div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span style="font-size: 15px"><br /></span>
            </div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span
                >浙江省文化旅游厅副厅长许澎在发言中着重提出，加强跟数字化服务商结合，提升管理水平，提升饭店业数字化改革；利用数字化技术强化行业转型，助推行业发</span
              ><span
                >展。随着行业进一步进入同质化淘汰、数量化破产、成本量增加、现金<span
                  style="
                    color: rgb(51, 51, 51);
                    font-size: 17px;
                    font-weight: 400;
                  "
                  >变</span
                >流量，饭店企业急需转型升级。从组织架构、转型定位、营销模式、产品设计、科技含量、服务重构等多方面推进行业变革转型，助推行业良性发展。</span
              >
            </div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span
                ><br
              /></span>
            </div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span
                ><br
              /></span>
            </div>
            <div style="text-align: center; margin-left: 8px; margin-right: 8px">
              <span
                ><img
                  class="rich_pages wxw-img js_insertlocalimg"
                  src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_png/MBcic5TYfW3augKXMJRM9Q1fxzyuFNIfPku7Ic8r8FkKa1iba3ZvqYEXrQ6YjcFfz4En4PGpAic5BE7ZbrKe4bsDA/640?wx_fmt=png"
                  style="
                    margin: 0px;
                    padding: 0px;
                    max-width: 100%;
                    height: 61px;
                    vertical-align: bottom;
                    color: rgb(51, 51, 51);
                    font-size: 17px;
                    font-weight: 400;
                    text-align: center;
                    width: 138px;
                  "
              /></span>
            </div>
            <div style="text-align: center; margin-left: 8px; margin-right: 8px"><br
              />
            </div>
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <img
                class="rich_pages wxw-img"
                src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_jpg/MBcic5TYfW3augKXMJRM9Q1fxzyuFNIfPTIrYnY0ZlMAxiau3px2wJZ8ZFV0ianhjL5Xs0qXMnqW2zSsYcMgtPdHA/640?wx_fmt=jpeg"
              />
            </div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span
                ><span
                  style="
                    color: rgb(51, 51, 51);
                    font-size: 14px;
                  "
                  >▲</span
                ><span
                  style="
                    color: rgb(51, 51, 51);
                    font-size: 14px;
                  "
                ></span
                ><span
                  style="
                    font-size: 10.5pt;
                  "
                  >浙江省饭店协会会长、君澜酒店集团总裁&nbsp; 王建平</span
                ></span
              >
            </div>
            <div style="margin-left: 8px; margin-right: 8px"><br /></div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span
                >浙江省饭店协会会长、杭州君澜集团总裁王建平也在发言中指出，全省饭店业将继续践行新发展理念，处理好发展与减排、整体与局部、短期与长期的关系，践行“双碳”战略，把行业可持续发展始终视作人民生活幸福感和人类命运共同体的关键点。为此，协会将积极承办好省文化和旅游厅“浙江省饭店业碳达峰工作推进会暨绿色低碳成果展”，总结行业发展经验，适时发布《浙江省饭店业绿色饭店建设发展报告》，推广饭店企业节能降耗、低碳环保的发展成果，助力行业绿色低碳发展。</span
              >
            </div>
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <br />
            </div>
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <br />
            </div>
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <img
                class="rich_pages wxw-img js_insertlocalimg"
                src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_png/MBcic5TYfW3augKXMJRM9Q1fxzyuFNIfPGRzGlEw1AiaAdFNGIGQEWCx1WFicarmCSUI44cFOkBS8Or4e0sHHkk3A/640?wx_fmt=png"
                style="
                  margin: 0px;
                  padding: 0px;
                  max-width: 100%;
                  height: 61px;
                  vertical-align: bottom;
                  color: rgb(51, 51, 51);
                  font-size: 17px;
                  font-weight: 400;
                  text-align: center;
                  width: 138px;
                "
              />
            </div>
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <br />
            </div>
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <img
                class="rich_pages wxw-img"
                src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_jpg/MBcic5TYfW3augKXMJRM9Q1fxzyuFNIfP2sKvy5pQkg1CNMQmGZhwP1SAdSmKRI6PuHB5c6XibJZzk6C6iamQ8yjg/640?wx_fmt=jpeg"
              />
            </div>
            <div
              style="
                text-align: left;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <span
                style="
                  margin: 0px;
                  padding: 0px;
                  color: rgb(51, 51, 51);
                  font-size: 14px;
                "
                >▲</span
              ><span
                style="
                  margin: 0px;
                  padding: 0px;
                  color: rgb(51, 51, 51);
                  font-size: 10.5pt;
                "
                >圆桌会议</span
              >
            </div>
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <br />
            </div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span
                >在圆桌会议中
                ，代表们表示在疫情背景下，鉴于当前经济大环境和市场发展，应该做好饭业行业的转型。如何做好酒店业的资产瘦身及软实力的提升，就变成了现在的重中之重，这就需要与数字化相结合，并做好酒店产品、技术、服务、人力、管理等多方面资源的融合。他们还表示，酒店行业是最早接受国际化的行业，是带着高端，先进的标签在引领着服务业的发展，而在数字化方面却落后于其他行业。</span
              >
            </div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span
                ><br
              /></span>
            </div>
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <img
                class="rich_pages wxw-img"
                src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_jpg/MBcic5TYfW3augKXMJRM9Q1fxzyuFNIfPt1r53XyP8smDzzx1fnVWj2gnAEAeq7Kt9Y62krYOw9ntKNepIXBxqQ/640?wx_fmt=jpeg"
              />
            </div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span
                ><span
                  style="
                    margin: 0px;
                    padding: 0px;
                    color: rgb(51, 51, 51);
                  "
                  >▲</span
                ><span
                  style="
                    margin: 0px;
                    padding: 0px;
                    color: rgb(51, 51, 51);
                    font-size: 10.5pt;
                  "
                  >颁奖典礼</span
                ></span
              >
            </div>
            <div style="margin-left: 8px; margin-right: 8px"><br /></div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span
                >这些行业先锋，在会谈中表达了他们在行业发展中的深思与远虑。酒店行业在未来的转型中，任重而道远，他们也将带着责任和愿景为饭店业寻找更好的发展方向而踌躇满志。</span
              >
            </div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span
                ><br
              /></span>
            </div>
            <div style="margin-left: 8px; margin-right: 8px">
              <span style=""
                >面对酒店行业的现状和未来发展，互为也更加清楚接下来要做什么，<span
                  style="
                    color: rgb(51, 51, 51);
                    font-size: 17px;
                  "
                  >我们</span
                >将在酒店行业数字化转型领域深度耕耘，继续引领饭店业低碳节能</span
              ><span style="">行动</span><span style="">。</span>
            </div>
            <div style="margin-left: 8px; margin-right: 8px"><br /></div>
            <div
              style="
                text-align: center;
                margin-bottom: 0em;
                margin-left: 8px;
                margin-right: 8px;
              "
            >
              <img
                class="rich_pages wxw-img"
                src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_png/MBcic5TYfW3b3gVnZOub9MCe7joomq1IAmicZEvbPUDqCUTdOJpxqGtHuYibzgKrUuKn5HVTx657tcUAZKIcicicIicA/640?wx_fmt=png"
              />
            </div>
          </div>
          <div class="article-footer"></div>
        </div>
      </div>
      <div class="list_nav">
        <div class="title_box">
          <img src="https://sys.huwei.xin:7443/huwei-website/icon/list_icon.png" alt="" />
          全部列表
        </div>
        <div class="list_box">
          <div
            class="list"
            :class="index == 0 ? 'new_box' : ''"
            @click="go(index)"
            v-for="(item, index) in informationList"
            :key="item.title"
          >
            <img
              class="img"
              :src="'https://sys.huwei.xin:7443/huwei-website/' + item.img"
              alt=""
            />
            <div class="list_content">
              <div class="title">{{ item.title }}</div>
              <div class="bottom">
                <div>
                  <span
                    ><img
                      src="https://sys.huwei.xin:7443/huwei-website/icon/time_icon.png"
                      alt="" /></span
                  >{{ item.date }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { Back } from "@element-plus/icons";
export default {
  name: "greenEnergy",
  components: { Back },
  setup() {
    const dom = getCurrentInstance();
    const state = computed(() => useStore().state.client);
    const router = useRouter();
    const informationList = ref([
      {
        img: "information-1.png",
        title: "首秀惊艳，未来可期 | 首届数贸会在杭州圆满落幕，互为“绿能专家”收获颇丰！",
        date: "2022-12-20  15:30",
      },
      {
        img: "information-2.png",
        title: "行业动态 | 2022年浙江省饭店业协会年度风云大会",
        date: "2022-09-01  16:01",
      },
    ]);
    const _back = () => {
      router.push("/about");
    };
    const go = (i) => {
      if (i == 0) {
        router.push("/information");
      } else if (i == 1) {
        router.push("/information_2");
      }
    };
    return {
      dom,
      state,
      _back,
      informationList,
      go,
    };
  },
};
</script>
<style lang="scss" scoped>
.content_nav {
  display: grid;
  padding: 0 168px 0 276px;
  grid-template-columns: 900px auto;
  grid-gap: 69px;
  line-height: 25px;
}

.list_nav {
  .title_box {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 26px;
    color: #222222;
    line-height: 35px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .list_box {
    padding-top: 10px;

    .list {
      display: flex;
      padding: 10px;
      position: relative;
      cursor: pointer;

      .new {
        position: absolute;
        background: #000000;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 300;
        color: #ffffff;
        line-height: 20px;
        padding: 5px 20px;
        top: 50px;
        left: 50px;
        opacity: 0.53;
      }

      .img {
        width: 195px;
        height: 131px;
      }

      .list_content {
        padding-left: 15px;
        display: grid;
        grid-template-rows: auto 25px;

        .title {
          font-size: 20px;
          color: #222222;
          line-height: 27px;
          font-weight: 600;
        }

        .bottom {
          font-size: 14px;
          font-weight: 300;
          color: #474747;
          line-height: 20px;

          .mark {
            color: #1667ff;
          }

          img {
            height: 20px;
            width: 20px;
            position: relative;
            top: 4px;
            left: 0px;
            margin-right: 5px;
          }
        }
      }
    }

    .list:hover {
      box-shadow: 0px 0px 20px 3px #ebedf1;
      transition: all ease-in-out 0.3s;
    }

    .new_box {
      background: #f7f7f7;
    }
  }
}

.button_nav {
  height: 50px;
  box-sizing: border-box;
  padding: 20px 278px;
  display: flex;
  justify-content: start;
  align-items: center;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .icon_box {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border: 1px solid #2222228c;
    color: #2222228c;
    margin-right: 15px;
  }

  .back_text {
    font-size: 18px;
    font-weight: 300;
    color: #2222228c;
    line-height: 25px;
  }
}

.button_nav:hover {
  .icon_box {
    color: #1667ff;
    border: 1px solid #1667ff;
  }

  .back_text {
    color: #1667ff;
  }
}

.yuanchuang {
  width: 70px;
  height: 30px;
  background: #0064ff14;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #1667ff;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green {
  font-size: 18px;
  font-weight: 500;
  color: #474747;
  line-height: 25px;
  padding: 0 20px 0 10px;
}

.dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.48);
  top: 0;
  left: 0;
  justify-content: center;
}

.dialog,
.dialog .inner {
  display: flex;
  align-items: center;
}

.dialog .inner {
  width: 400px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  justify-content: flex-start;
  flex-direction: column;
  padding: 22px 30px 16px;
  position: relative;
  background: #fff;
}

.dialog .inner > .close-icon {
  width: 14px;
  height: 14px;
  padding: 4px;
  box-sizing: content-box;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 12px;
  right: 18px;
}

.dialog .inner > .title {
  font-size: 16px;
  font-weight: 700;
  color: #222;
  width: 100%;
  text-align: left;
}

.dialog .inner > .content {
  width: 100%;
  font-size: 14px;
  color: #222;
  white-space: pre-wrap;
  
  text-align: left;
  line-height: 18px;
  margin-top: 10px;
}

.dialog .inner > .action-wrap {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dialog .inner > .action-wrap .btn {
  line-height: 32px;
}

.dialog .inner > .action-wrap .cancel-btn {
  outline: none;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  color: #333;
  background: #fff;
  transition: background 0.3s;
}

.dialog .inner > .action-wrap .cancel-btn:active {
  background: #f2f2f2;
  border: 1px solid #cfcfcf;
}

.dialog .inner > .action-wrap .cancel-btn:hover {
  background: #f8f8f8;
  border: 1px solid #d3d3d3;
}

.dialog .inner > .action-wrap .btn.primary {
  margin-left: 12px;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  background: #24be48;
  border-radius: 4px;
  color: #fff;
  transition: background 0.3s;
}

.dialog .inner > .action-wrap .btn.primary:active {
  background: #1fa63f;
}

.dialog .inner > .action-wrap .btn.primary:hover {
  background: #21af42;
}

.link-btn {
  color: #8f9194;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  font-size: 14px;
}

.link-btn:hover {
  text-decoration: underline;
}

.btn {
  outline: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 0 20px;
  white-space: nowrap;
  color: #000;
  font-size: 14px;
  background: #fff;
  box-sizing: content-box;
  cursor: pointer;
  user-select: none;
  height: 32px;
  line-height: 32px;
  transition: all 0.2s ease 0s;
}

.btn:hover {
  color: #333;
  background: #f8f8f8;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
}

.btn:active {
  color: #1a1a1a;
  background: #f2f2f2;
  border-radius: 4px;
  border: 1px solid #cfcfcf;
}

.btn.primary {
  background: #24be48;
  color: #fff;
  border: 1px solid #24be48;
}

.btn.primary:hover {
  background: #21af42;
  color: #fff;
}

.btn.primary:active {
  background: #1fa63f;
}

.btn.primary.disable {
  background: #f5f5f5;
  color: #b8b8b8;
  border: 1px solid transparent;
  
}

.btn.primary.disable:hover {
  opacity: 1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  font-family: mp-quote, -apple-system-font, BlinkMacSystemFont, Helvetica Neue,
    PingFang SC, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

.tip-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fdf5e8;
}

@media screen and (max-width: 1024px) {
  #page-root {
    width: 100%;
    height: 100%;
    background: #fff;
    padding-top: 1.09312rem;
    padding-left: 0.6832rem;
    padding-right: 0.6832rem;
    // overflow-x: hidden;
    // overflow-y: scroll;
    line-height: 30px;
  }

  #page-root .tip-container {
    padding: 5px 15px;
    font-size: 14px;
    
    font-weight: 400;
    color: #f09a47;
    line-height: 20px;
  }

  #page-root .article-container {
    height: 100%;
    margin-top: 50px;
    line-height: 25px;
  }

  #page-root .article-container .title {
    font-size: 1.50304rem;
    margin-bottom: 0.95648rem;
    color: #333;
  }

  #page-root .article-container .article-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.50304rem;
    line-height: 1.3664rem;
    
    
    font-size: 0.81984rem;
  }

  #page-root .article-container .article-info .author {
    color: #576b95;
    margin-right: 0.6832rem;
  }

  #page-root .article-container .article-info .timestamp {
    color: #8d8d8d;
    margin-right: 0.40992rem;
  }

  #page-root .article-container .article-content-wrap {
    width: 100%;
    padding-bottom: 2.7328rem;
  }

  #page-root .article-container .article-content-wrap .article-content {
    color: #333;
    text-align: justify;
    font-size: 1.16144rem;
    
    margin-bottom: 0.6832rem;
  }

  #page-root .article-container .article-content-wrap .article-content * {
    max-width: 100% !important;
    box-sizing: border-box !important;
    
    
  }

  #page-root .article-container .article-content-wrap .article-content img {
    height: auto !important;
  }

  #page-root .article-container .article-content-wrap .article-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #page-root .article-container .article-content-wrap .article-footer .reading {
    font-size: 0.75152rem;
    color: #b6b6b6;
  }

  #page-root .article-container .article-content-wrap .article-footer .watching {
    display: flex;
    align-items: center;
  }

  #page-root
    .article-container
    .article-content-wrap
    .article-footer
    .watching
    .watch-icon {
    width: 0.95648rem;
    height: 1.09312rem;
    margin-right: 0.27328rem;
  }

  #page-root .article-container .article-content-wrap .article-footer .watching > span {
    color: #607191;
    font-size: 0.75152rem;
  }

  .dialog .inner {
    width: 80%;
  }

  .dialog .inner .content {
    margin-top: 0.6832rem;
  }

  .dialog .inner .action-wrap {
    margin-top: 1.3664rem;
  }
}

@media screen and (min-width: 1024px) {
  #page-root {
    width: 100%;
    height: 100%;
    background: #fff;
    padding-top: 32px;
    // overflow-x: hidden;
    // overflow-y: scroll;
  }

  #page-root .tip-container {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    
    font-weight: 400;
    color: #f09a47;
  }

  #page-root .article-container {
    width: 900px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }

  #page-root .article-container .title {
    font-size: 22px;
    margin-bottom: 14px;
    color: #333;
  }

  #page-root .article-container .article-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 22px;
    line-height: 20px;
    
    
  }

  #page-root .article-container .article-info .author {
    color: #576b95;
    margin-right: 10px;
  }

  #page-root .article-container .article-info .timestamp {
    color: #8d8d8d;
    font-size: 18px;
    margin-right: 6px;
  }

  #page-root .article-container .article-content-wrap {
    width: 100%;
    padding-bottom: 40px;
  }

  #page-root .article-container .article-content-wrap .article-content {
    color: #333;
    text-align: justify;
    font-size: 17px;
    
    margin-bottom: 10px;
  }

  #page-root .article-container .article-content-wrap .article-content * {
    max-width: 100% !important;
    box-sizing: border-box !important;
    
    
  }

  #page-root .article-container .article-content-wrap .article-content img {
    height: auto !important;
  }

  #page-root .article-container .article-content-wrap .article-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #page-root .article-container .article-content-wrap .article-footer .reading {
    font-size: 11px;
    color: #576b95;
  }

  #page-root .article-container .article-content-wrap .article-footer .watching {
    display: flex;
    align-items: center;
  }

  #page-root
    .article-container
    .article-content-wrap
    .article-footer
    .watching
    .watch-icon {
    width: 14px;
    height: 16px;
    margin-right: 4px;
  }

  #page-root .article-container .article-content-wrap .article-footer .watching > span {
    color: #607191;
    font-size: 11px;
  }
}

.article-content-wrap .loading-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40%;
  width: 2.0496rem;
  height: 2.0496rem;
}

.article-content-wrap .loading-icon.in-pc {
  width: 240px;
  height: 240px;
  top: 20%;
}

.partner_nav {
  height: 706px;
  padding: 202px 350px 150px;
  box-sizing: border-box;

  .partner_title {
    font-size: 36px;
    font-weight: 800;
    color: #3d3f55;
    line-height: 36px;
    text-align: center;
    padding-bottom: 80px;
  }

  .partner_box {
    display: grid;
    grid-gap: 80px 140px;
    grid-template-columns: repeat(4, 1fr);

    .img {
      width: 200px;
      height: 79px;
      margin-top: -30px;
    }
  }
}

#allmap {
  // 注意给dom宽高，不然地图不出来
  width: 100%;
  height: 400px;

  :deep(.BMap_cpyCtrl, .anchorBL) {
    display: none !important;
  }
}
</style>
